@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:200&display=swap);
:root {
  --font-size-base: 24px;

  --indent-xs: 4px;
  --indent-s: 8px;
  --indent-m: 16px;
  --indent-l: 24px;
  --indent-xl: 36px;
  --indent-xxl: 48px;

  --max-width-limit: 1400px;
}

/* sm */
@media (min-width: 600px) {
  :root {
  }
}

/* md */
@media (min-width: 960px) {
  :root {
  }
}

/* lg */
@media (min-width: 1280px) {
  :root {
  }
}

/* xl */
@media (min-width: 1920px) {
  :root {
  }
}

html, body {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

button,
input,
textarea {
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  padding: 0;
  letter-spacing: inherit;
}

a {
  text-decoration: none;
}

p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}


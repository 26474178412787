@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:200&display=swap');

html, body {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

button,
input,
textarea {
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  padding: 0;
  letter-spacing: inherit;
}

a {
  text-decoration: none;
}

p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
